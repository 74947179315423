import { Controller } from "@hotwired/stimulus";

import L from "leaflet";

export default class extends Controller {
  static targets = ["map"];
  static values = {
    layer: String,
    attribution: String,
  };

  resize(event) {
    this.map.invalidateSize();
  }

  mapTargetConnected(map) {
    this.map;
  }

  layerValueChanged() {
    if (this._layer) {
      this.map.removeLayer(this._layer);
      this._layer = undefined;
    }

    this.layer;
  }

  get map() {
    if (!this._map) {
      this._map = L.map(this.mapTarget, {
        zoom: 0,
        minZoom: 0,
        maxZoom: 5,
        attributionControl: false,
      }).setView([0, 0], 0);
    }

    return this._map;
  }

  get layer() {
    if (!this._layer) {
      this._layer = L.tileLayer(this.layerValue, {
        minNativeZoom: 0,
        maxNativeZoom: 5,
        noWrap: true,
      }).addTo(this.map);

      L.control
        .attribution({ prefix: false })
        .addAttribution(this.attributionValue)
        .addTo(this.map);
    }

    return this._layer;
  }
}
